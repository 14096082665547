/* roboto-300italic - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-300italic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-300italic.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-italic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-italic.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500italic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500italic.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700italic - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700italic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700italic.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900italic - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-900italic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-900italic.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-v30-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-900italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
