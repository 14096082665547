.mat-mdc-tooltip-panel {
  .mdc-tooltip__surface {
    padding: 8px;
    background: var(--black-700);
    opacity: 0.9;
    border-radius: 6px;
  }

  .mdc-tooltip {
    &:after {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      background: var(--black-700);
      border-right: 2px;
      z-index: 7;
      transform: rotate(45deg);
    }
  }

  &-above {
    .mdc-tooltip {
      &:after {
        bottom: -6px;
        left: calc(50% - 6px);
      }
    }
  }

  &-below {
    .mdc-tooltip {
      &:after {
        top: -6px;
        left: calc(50% - 6px);
      }
    }
  }
  &-below {
    .mdc-tooltip {
      &:after {
        top: -6px;
        left: calc(50% - 6px);
      }
    }
  }

  &-before,
  &-right {
    .mdc-tooltip {
      &:after {
        top: calc(50% - 6px);
        left: -6px;
      }
    }
  }

  &-after,
  &-left {
    .mdc-tooltip {
      &:after {
        top: calc(50% - 6px);
        right: -6px;
      }
    }
  }
}
