mat-error {
  display: flex;
  align-items: center;
  gap: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--error-500);
  letter-spacing: unset;
}
