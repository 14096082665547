// CHECKBOX

.mat-mdc-checkbox {

  .mdc-checkbox {
    padding: 0 !important;
    max-width: 16px;
    max-height: 16px;
    margin-top: 3px !important;

    .mat-mdc-checkbox-touch-target {
      width: 16px !important;
      height: 16px !important;
    }


    .mdc-checkbox__background {
      border: 1px solid var(--black-500);
      border-radius: 4px !important;
      width: 16px;
      height: 16px;
      top: 0 !important;
      left: 0 !important;
      z-index: 3;

      .mdc-checkbox__checkmark {
        width: 10px;
        height: 10px;
        top: 2px;
        left: 2px;
      }

      .mdc-checkbox__mixedmark {
        width: 10px;
        border-width: 0.5px;
        border-radius: 2px
      }
    }

    .mdc-checkbox__native-control {
      width: 0 !important;
      height: 0 !important;
    }

    .mdc-checkbox__ripple {
      position: absolute;
      left: calc(50% - 15px);
      top: calc(50% - 15px);
      height: 30px;
      width: 30px;
      z-index: 1;
      pointer-events: none;
      background-color: rgba(143, 143, 142, 0.55);
    }
  }

  .mdc-form-field {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    label {
      padding: 2px 0 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--black-700);
    }
  }
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  &:enabled:indeterminate ~ .mdc-checkbox__background {
    background-color: var(--makktina-2-600) !important;
    border: 1px solid var(--makktina-2-600) !important;
  }

  &[disabled] {
    &:checked ~ .mdc-checkbox__background {
      background: url("~src/assets/images/icons/check.svg") center center no-repeat var(--black-300) !important;
      border: 1px solid var(--black-300) !important;
      background-size: 12px !important;
    }

    &:indeterminate ~ .mdc-checkbox__background {
      background: var(--black-300) !important;
      border: 1px solid var(--black-300) !important;
      background-size: 10px !important;
    }
  }
}

.mat-mdc-checkbox-checked.mat-primary {
  .mdc-checkbox__background {
    background: url("~src/assets/images/icons/check.svg") center center no-repeat var(--makktina-2-600) !important;
    border: 1px solid var(--makktina-2-600) !important;
    background-size: 12px !important;
    border-radius: 4px !important;
    overflow: hidden;

    svg.mdc-checkbox__checkmark {
      display: none
    }
  }

  .mdc-checkbox__ripple {
    background-color: var(--makktina-2-100) !important;
  }
}

.mat-checkbox-layout .mat-checkbox-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--black-600);
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background: var(--makktina-2-600);

}

.mat-checkbox-inner-container {
  width: 16px !important;
  height: 16px !important;
}

.mat-checkbox-mixedmark {
  width: calc(100% - 8px) !important;
  height: 1px !important;
}

// RADIO
.ui-radio-button {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-700);
  display: flex;
  align-items: center;
  gap: 8px;

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    transition: 0.2s all ease-in-out;

    &:after {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      border: 1px solid var(--black-500);
      border-radius: 50%;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.6);
      transition: 0.2s all ease-in-out;
    }


    &:checked {
      &:before {
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        top: calc(50% - 4px);
        left: calc(50% - 4px);
        border-radius: 50%;
        overflow: hidden;
        background: var(--makktina-2-500);
        transition: 0.2s all ease-in-out;
        z-index: 9;
      }

      &:disabled {
        &:before {
          background: var(--black-300) !important;
        }
      }
    }

    &:checked,
    &:hover {
      &:after {
        border-color: var(--makktina-2-500);
      }
    }

    &:focus {
      &:after {
        box-shadow: 0 0 0 2px #FDE4F3, 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
      }
    }

    &:disabled {
      pointer-events: none;

      &:after {
        border-color: var(--black-300) !important;
      }
    }
  }
}

mat-radio-button {
  .mdc-form-field {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    .mdc-radio {
      padding: 0;
    }

    label {
      padding: 0;
    }
  }
}

.offer-checkbox {
  z-index: 10;

  a {
    text-decoration-line: underline;
  }
  ::ng-deep .mdc-form-field label {
    padding: 3px 0 0px;
    color: var(--black-600, #676766);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}
