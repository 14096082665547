input.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: normal;

  &::placeholder {
    color: var(--black-400) !important;
  }
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--slate-900);
}
