mat-hint {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--black-500);
  letter-spacing: unset;
  margin-top: 2px;

  ui-icon {
    .icon {
      width: 16px;
      height: 16px;
      background: var(--black-400);
    }
  }

  .form-field-counter {
    margin-left: auto;
  }
}


