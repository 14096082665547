:root {
  // Makktina 1
  --makktina-1-50: #FCEEF5;
  --makktina-1-100: #F8D3E6;
  --makktina-1-200: #F2A6CC;
  --makktina-1-300: #EF90C0;
  --makktina-1-400: #EA3F91;
  --makktina-1-500: #EA117B;
  --makktina-1-600: #D81277;
  --makktina-1-700: #C11370;
  --makktina-1-800: #AB126A;
  --makktina-1-900: #85105F;

  // Makktina 2
  --makktina-2-50: #FDE4F1;
  --makktina-2-100: #FBBCE9;
  --makktina-2-200: #FC9CD2;
  --makktina-2-300: #FF5BB5;
  --makktina-2-400: #FF52B4;
  --makktina-2-500: #FF008A;
  --makktina-2-600: #F00086;
  --makktina-2-700: #D8007F;
  --makktina-2-800: #C1007A;
  --makktina-2-900: #850063;

  // Makktina 3
  --makktina-3-50: #FFEBF1;
  --makktina-3-100: #FFCCDC;
  --makktina-3-200: #FE9AB9;
  --makktina-3-300: #FB6594;
  --makktina-3-400: #F8447C;
  --makktina-3-500: #F62565;
  --makktina-3-600: #E42162;
  --makktina-3-700: #CE1D5D;
  --makktina-3-800: #B9195A;
  --makktina-3-900: #941153;

  // Black
  --black-50: #f8f8f7;
  --black-100: #f0f0ee;
  --black-200: #e5e5e4;
  --black-300: #d4d4d2;
  --black-400: #afafae;
  --black-500: #8f8f8e;
  --black-600: #676766;
  --black-700: #545453;
  --black-800: #363635;
  --black-900: #161615;

  // White
  --white-20: rgba(255, 255, 255, 0.2);
  --white-40: rgba(255, 255, 255, 0.4);
  --white-50: rgba(255, 255, 255, 0.5);
  --white-60: rgba(255, 255, 255, 0.6);
  --white-80: rgba(255, 255, 255, 0.8);
  --white-100: rgba(255, 255, 255);

  // Blue
  --blue-50: #EFF6FF;
  --blue-100: #DBEAFE;
  --blue-200: #BFDBFE;
  --blue-300: #93C5FD;
  --blue-400: #60A5FA;
  --blue-500: #3B82F6;
  --blue-600: #2563EB;
  --blue-700: #1D4ED8;
  --blue-800: #1E40AF;
  --blue-900: #1E3A8A;

  // Success
  --success-50: #ECFDF5;
  --success-100: #D1FAE5;
  --success-200: #A7F3D0;
  --success-300: #6EE7B7;
  --success-400: #34D399;
  --success-500: #10B981;
  --success-600: #059669;
  --success-700: #047857;
  --success-800: #065F46;
  --success-900: #064E3B;

  // Validation
  --validation-50: #FFFBEB;
  --validation-100: #FEF3C7;
  --validation-200: #FDE68A;
  --validation-300: #FCD34D;
  --validation-400: #FBBF24;
  --validation-500: #F59E0B;
  --validation-600: #D97706;
  --validation-700: #B45309;
  --validation-800: #92400E;
  --validation-900: #78350F;

  // Error
  --error-50: #FFF1F2;
  --error-100: #FFE4E6;
  --error-200: #FECDD3;
  --error-300: #FDA4AF;
  --error-400: #FB7185;
  --error-500: #F3425F;
  --error-600: #E11D48;
  --error-700: #BE123C;
  --error-800: #9F1239;
  --error-900: #881337;

  // Slate
  --slate-50: #F8FAFC;
  --slate-100: #F1F5F9;
  --slate-200: #F1F5F9;
  --slate-300: #CBD5E1;
  --slate-400: #94A3B8;
  --slate-500: #64748B;
  --slate-600: #475569;
  --slate-700: #334155;
  --slate-800: #1E293B;
  --slate-900: #0F172A;

  // Yellow
  --yellow-50: #FEFCE8;
  --yellow-100: #FEF9C3;
  --yellow-200: #FEF08A;
  --yellow-300: #FDE047;
  --yellow-400: #FACC15;
  --yellow-500: #EAB308;
  --yellow-600: #CA8A04;
  --yellow-700: #A16207;
  --yellow-800: #854D0E;
  --yellow-900: #713F12;

  // Sky
  --sky-50: #F0F9FF;
  --sky-100: #E0F2FE;
  --sky-200: #BAE6FD;
  --sky-300: #7DD3FC;
  --sky-400: #38BDF8;
  --sky-500: #0EA5E9;
  --sky-600: #0284C7;
  --sky-700: #0369A1;
  --sky-800: #075985;
  --sky-900: #0C4A6E;

  // Indigo
  --indigo-50: #EEF2FF;
  --indigo-100: #E0E7FF;
  --indigo-200: #C7D2FE;
  --indigo-300: #A5B4FC;
  --indigo-400: #818CF8;
  --indigo-500: #6366F1;
  --indigo-600: #4F46E5;
  --indigo-700: #4338CA;
  --indigo-800: #3730A3;
  --indigo-900: #312E81;

  // Orange
  --orange-50: #FFF7ED;
  --orange-100: #FFEDD5;
  --orange-200: #FED7AA;
  --orange-300: #FDBA74;
  --orange-400: #FB923C;
  --orange-500: #F97316;
  --orange-600: #EA580C;
  --orange-700: #C2410C;
  --orange-800: #9A3412;
  --orange-900: #7C2D12;

  // Amber
  --amber-50: #FFF7ED;
  --amber-100: #FFEDD5;
  --amber-200: #FED7AA;
  --amber-300: #FDBA74;
  --amber-400: #FB923C;
  --amber-500: #F97316;
  --amber-600: #EA580C;
  --amber-700: #C2410C;
  --amber-800: #9A3412;
  --amber-900: #7C2D12;

  // Teal
  --teal-50: #F0FDFA;
  --teal-100: #CCFBF1;
  --teal-200: #99F6E4;
  --teal-300: #5EEAD4;
  --teal-400: #2DD4BF;
  --teal-500: #14B8A6;
  --teal-600: #0D9488;
  --teal-700: #0F766E;
  --teal-800: #115E59;
  --teal-900: #134E4A;

  // Cyan
  --cyan-50: #ECFEFF;
  --cyan-100: #CFFAFE;
  --cyan-200: #A5F3FC;
  --cyan-300: #67E8F9;
  --cyan-400: #22D3EE;
  --cyan-500: #06B6D4;
  --cyan-600: #0891B2;
  --cyan-700: #0E7490;
  --cyan-800: #155E75;
  --cyan-900: #164E63;

  // Violet
  --violet-50: #F5F3FF;
  --violet-100: #EDE9FE;
  --violet-200: #DDD6FE;
  --violet-300: #C4B5FD;
  --violet-400: #A78BFA;
  --violet-500: #8B5CF6;
  --violet-600: #7C3AED;
  --violet-700: #6D28D9;
  --violet-800: #5B21B6;
  --violet-900: #4C1D95;

  // Purple
  --purple-50: #FAF5FF;
  --purple-100: #F3E8FF;
  --purple-200: #E9D5FF;
  --purple-300: #D8B4FE;
  --purple-400: #C084FC;
  --purple-500: #A855F7;
  --purple-600: #9333EA;
  --purple-700: #7E22CE;
  --purple-800: #6B21A8;
  --purple-900: #581C87;

  // Fuchsia
  --fuchsia-50: #FDF4FF;
  --fuchsia-100: #FAE8FF;
  --fuchsia-200: #F5D0FE;
  --fuchsia-300: #F0ABFC;
  --fuchsia-400: #E879F9;
  --fuchsia-500: #D946EF;
  --fuchsia-600: #C026D3;
  --fuchsia-700: #A21CAF;
  --fuchsia-800: #86198F;
  --fuchsia-900: #701A75;

  // Rose
  --rose-50: #FEF2F2;
  --rose-100: #FEE2E2;
  --rose-200: #FECACA;
  --rose-300: #FCA5A5;
  --rose-400: #F87171;
  --rose-500: #EF4444;
  --rose-600: #DC2626;
  --rose-700: #B91C1C;
  --rose-800: #991B1B;
  --rose-900: #7F1D1D;
}
