.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: var(--black-900);
  -webkit-mask-size: contain !important;
  mask-size: contain !important;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  &-close-fill {
    -webkit-mask: url('../assets/images/icons/close-fill.svg') no-repeat 50% 50%;
    mask: url('../assets/images/icons/close-fill.svg') no-repeat 50% 50%;
  }

  &-eye-slash {
    -webkit-mask: url('../assets/images/icons/eye-slash.svg') no-repeat 50% 50%;
    mask: url('../assets/images/icons/eye-slash.svg') no-repeat 50% 50%;
  }

  &-eye-fill {
    -webkit-mask: url('../assets/images/icons/eye-fill.svg') no-repeat 50% 50%;
    mask: url('../assets/images/icons/eye-fill.svg') no-repeat 50% 50%;
  }

  &-drag {
    -webkit-mask: url('../assets/images/icons/drag.svg') no-repeat 50% 50%;
    mask: url('../assets/images/icons/drag.svg') no-repeat 50% 50%;
  }
}
