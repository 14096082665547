.custom-carousel {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .owl-stage {
    display: flex;
    align-items: stretch;
    padding: 0 0 36px !important;
    position: relative;
  }

  .right-shadow {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    box-shadow: -20px 0px 144px 200px rgb(255 255 255 / 79%);
    z-index: 9;
  }


  ::ng-deep .carousel-item {
    cursor: pointer;
  }

  .slider-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0 0;
    margin: 0 240px;
    border-top: 1px solid #E5E5E4;
  }

  .owl-nav.disabled {
    display: none;
  }


  @media screen and (max-width: 1280px) {
    .carousel-container {
      padding: 24px 85px;
    }
    .slider-controls {
      margin: 0 85px;
    }
  }

  @media screen and (max-width: 768px) {
    .carousel-container {
      overflow-x: auto;
      padding: 24px;
    }

    .slider-controls {
      margin: 0 24px;

      ::ng-deep {
        .next .ui-link-container,
        .prev .ui-link-container {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }

        .see-all .ui-link-container {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .carousel-container {
      padding: 24px 24px 34px;
    }

    .next,
    .prev {
      display: none;
    }
    .slider-controls {
      justify-content: center;
      margin: 0 24px;
      padding: 0;
      border: 0;


      .see-all {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        height: 42px;
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid var(--black-300);
        border-radius: 6px;
      }
    }
  }
}


