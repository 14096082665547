mat-datepicker-content.mat-datepicker-content {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

div.mat-calendar-header {
  background-color: var(--makktina-2-500);
  border-radius: 6px 6px 0 0;
}

div.mat-calendar-controls {
  margin: 0 0 18px;
  justify-content: center;
  padding: 0 8px 8px;

  .mat-mdc-icon-button.mat-mdc-button-base {
    position: absolute;
  }
}

.mat-datepicker-content {
  .mat-calendar-next-button {
    right: 10px;
  }

  .mat-calendar-previous-button {
    left: 10px;
  }

  .mat-calendar-next-button,
  .mat-calendar-previous-button {
    color: var(--slate-50);
  }

  .mat-mdc-button:not(:disabled) {
    color: var(--white-80);
  }

  .mat-calendar-arrow {
    fill: var(--white-80);
  }
}

div.mat-calendar-spacer {
  display: none;
}

.mat-calendar-body-label {
  color: var(--white-100);
}

div.mat-calendar-body-cell-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 2px;
}

.mat-calendar-body-cell {
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }


  &:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--makktina-2-50);
  }
}

.mat-calendar-table * {
  outline: none !important;
}

.mat-calendar-body-selected {
  background: var(--error-400);
  border-color: var(--error-400);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border: unset;
  background-color: #fff;
  border: 1px solid var(--makktina-2-400);
  color: var(--makktina-2-400);

  &:hover {
    background-color: var(--makktina-2-500) !important;
    color: var(--slate-50);
  }
}


.custom-calendar {
  width: 240px;
  background: #FFFFFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  tr[aria-hidden="true"] {
    display: none !important;
  }

  .mat-calendar-body-label {
    color: transparent !important;
  }

  .mat-calendar-table-header {
    th {
      &:nth-last-child(-n + 2) {
        span {
          color: #F43F5E !important;
        }
      }
    }
  }

  * {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--black-600);

    .mat-calendar-arrow {
      fill: var(--black-600);
    }
  }

  .mat-calendar-controls {
    position: relative;
  }

  .mat-calendar-previous-button {
    left: 0;
  }

  .mat-calendar-next-button {
    right: 0;
  }

  div.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
    width: 30px;
    height: 30px;
    padding: 0;

    &::after {
      margin: 11px;
    }
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: transparent;
    color: #F87171;

    &:hover {
      color: #FFFFFF;
    }
  }

  .mat-calendar-table-header-divider {
    display: none;
  }

  div.mat-calendar-header {
    background: transparent;
  }

  .mat-calendar-body-active {

    .mat-calendar-body-cell-content {
      background: var(--makktina-1-400) !important;
      color: #fff !important;
    }
  }
}


.booking-calendar {
  background: #FFFFFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  .mat-calendar-content {
    padding: 0 32px;
  }

  tr[aria-hidden="true"] {
    display: none !important;
  }

  .mat-calendar-body-label {
    color: transparent !important;
  }

  .mat-calendar-table-header {
    th {
      &:nth-last-child(-n + 2) {
        span {
          color: #F43F5E !important;
        }
      }
    }
  }

  * {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--black-600);

    .mat-calendar-arrow {
      fill: var(--black-600);
    }
  }

  .mat-calendar-controls {
    position: relative;
  }

  .mat-calendar-previous-button {
    left: 0;
  }

  .mat-calendar-next-button {
    right: 0;
  }

  div.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
    width: 30px;
    height: 30px;
    padding: 0;

    &::after {
      margin: 11px;
    }
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: transparent;
    color: #F87171;

    &:hover {
      color: #FFFFFF;
    }
  }

  .mat-calendar-table-header-divider {
    display: none;
  }

  div.mat-calendar-header {
    background: transparent;
  }

  .mat-calendar-body-active {

    .mat-calendar-body-cell-content {
      background: var(--makktina-1-400) !important;
      color: #fff !important;
    }
  }
}
