.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 6px;
  color: #000;
  overflow: hidden;

  &-primary {
    background: var(--makktina-2-600);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    color: #fff;
  }

  &-secondary {
    background: var(--makktina-2-50);
    color: var(--makktina-2-600);
    border: 1px solid var(--makktina-2-200);
  }

  &-tertiary {
    background: var(--white-80);
    border: 1px solid var(--black-300);
    color: var(--black-700);
  }
}
